import React from "react"
import Image from "gatsby-image"
import parse from "html-react-parser"
// import { Link } from "gatsby"

const PrzepisKroki = ({kroki}) => {

  if (!kroki || kroki === 0) return null
  return (
    <section className="blog-post-kroki">
      <h2>Przepis krok po kroku:</h2>
        {kroki.map((krok, index) => (
          <div className="krok" key={krok.opis}>
            {krok?.ilustracja?.localFile?.childImageSharp?.fluid && (
              <a href={krok.ilustracja.localFile.publicURL} className="krok-ilustracja-link">
                <Image
                  fluid={krok.ilustracja.localFile.childImageSharp.fluid}
                  alt={krok.ilustracja.altText}
                  Tag="figure"
                />
              </a>
            )}
            <p><strong>Krok {index+1}.</strong> {parse(krok.opis)}</p>
          </div>
        ))}
    </section>
  )
}

export default PrzepisKroki