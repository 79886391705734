import React from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import parse from "html-react-parser"

// We're using Gutenberg so we need the block styles
import "@wordpress/block-library/build-style/style.css"
import "@wordpress/block-library/build-style/theme.css"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"

import YT from "../components/yt"
import PrzepisKroki from "../components/kroki"
import PostTerms from "../components/postterms"

import Ads from "../components/ads"
import Adsense1 from "../components/adsense1"
import Adsense2 from "../components/adsense2"
import Adsense3 from "../components/adsense3"

const BlogPostTemplate = ({ data: { previous, next, post, site }, location }) => {
  const featuredImage = {
    fluid: post?.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: post?.featuredImage?.node?.altText || ``,
    schemaSrc: post?.featuredImage?.node?.localFile?.childImageSharp?.fluid.src,
    schemaHeight: post?.featuredImage?.node?.localFile?.childImageSharp?.fluid.presentationHeight,
    schemaWidth: post?.featuredImage?.node?.localFile?.childImageSharp?.fluid.presentationWidth,
  }
  const author = post.author.node
  const terms = post.terms
  // const kroki = post.acfPrzepis.kotletPrzepisKrok

  const schemaCanonicalUrl = site.siteMetadata.siteUrl + location.pathname
  const schemaCategory = post.categories.nodes[0].name ? post.categories.nodes[0].name : null

  const schemaInstructions = post.acfPrzepis.kotletPrzepisKrok ? post.acfPrzepis.kotletPrzepisKrok.map((krok,index) => 'Krok ' + (index+1) + '. ' + krok.opis) : null

  const schemaInstructionsString = schemaInstructions ? schemaInstructions.join(" ") : null

  var schemaIngredients

  if(post.acfPrzepis.kotletPrzepisSkladniki) {
    schemaIngredients = post.acfPrzepis.kotletPrzepisSkladniki.replace('\n', '')
    schemaIngredients = schemaIngredients.replace(/(?<=<p>)(.*)(?=<\/p>)/gi, str => str.toUpperCase())
    schemaIngredients = schemaIngredients.replace(/(<ul>\n<li>)/gi, ' ')
    schemaIngredients = schemaIngredients.replace(/(<\/li>\n<\/ul>)/gi, '.^')
    schemaIngredients = schemaIngredients.replace('<li>', '')
    schemaIngredients = schemaIngredients.replace(/<li>/gi, ', ')
    schemaIngredients = schemaIngredients.replace(/(\n)/gi, '')
    schemaIngredients = schemaIngredients.replace(/(<([^>]+)>)/gi, '')
    schemaIngredients = schemaIngredients.split('^').filter(item => item)
    schemaIngredients = JSON.stringify(schemaIngredients)
  }

  const description = post.excerpt ? post.excerpt.substr(3, post.excerpt.length - 8) : null

  const schemaYield = post.acfPrzepis.kotletPrzepisLiczbaPorcji ? post.acfPrzepis.kotletPrzepisLiczbaPorcji : null

  const schemaRecipe = {
    "@context": "http://schema.org",						
    "@type": "Recipe",
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `${schemaCanonicalUrl}`
    },
    "name": `${post.title}`,
    "recipeCategory": `${schemaCategory}`,
    "image": {
      "@type": "ImageObject",
      "url": `${site.siteMetadata.siteUrl + featuredImage.schemaSrc}`,
      "height": `${featuredImage.schemaHeight}`,
      "width": `${featuredImage.schemaWidth}`
    },
    "datePublished": `${post.dateSchema}`,
    "author": {
      "@type": "Person",
      "name": `${author.name}`
    },
    "description": `${description}`,
    "recipeYield": `${schemaYield}`,
    "recipeIngredient": `${schemaIngredients}`,
    "recipeInstructions": `${schemaInstructionsString}`
  }

  return (
    <Layout currentPath={location.pathname}>
      <SEO title={post.title} description={description} />

      <article className="blog-post">

        {(!!post.acfPrzepis.kotletPrzepisLiczbaPorcji && !!post.acfPrzepis.kotletPrzepisCzas && !!post.acfPrzepis.kotletPrzepisSkladniki && !!post.acfPrzepis.kotletPrzepisKrok && !!featuredImage.schemaSrc) && (
          <Helmet>
            <script type="application/ld+json">
              {JSON.stringify(schemaRecipe)}
            </script>
          </Helmet>
        )}

        <header>
          <h1 className="blog-post-title">{parse(post.title)}</h1>

          <PostTerms terms={terms} />

          {/* if we have a featured image for this post let's display it */}
          {featuredImage?.fluid && (
            <Image
              fluid={featuredImage.fluid}
              alt={featuredImage.alt}
              className="post-featured-image"
              Tag="figure"
            />
          )}
        </header>

        {!!post.content && (
          <div className="blog-post-body1">{parse(post.content)}</div>
        )}

        <Adsense1 currentPath={location.pathname} />

        <div className="blog-post-info">

        {(!!post.acfPrzepis.kotletPrzepisLiczbaPorcji && !!post.acfPrzepis.kotletPrzepisCzas) && (
          <section className="blog-post-porcje">
            <h2>Czas i porcje:</h2>
            <ul>
              <li>Liczba porcji: <strong>{post.acfPrzepis.kotletPrzepisLiczbaPorcji}</strong></li>
              <li>Czas: <strong>{post.acfPrzepis.kotletPrzepisCzas}</strong></li>
            </ul>
          </section>
         )}

        {!!post.acfPrzepis.kotletPrzepisSkladniki && (
          <section className="blog-post-skladniki">
          <h2>Składniki:</h2>
          {parse(post.acfPrzepis.kotletPrzepisSkladniki)}
          </section>
         )}

        {!!post.acfPrzepis.kotletPrzepisPorady && (
          <section className="blog-post-porady">
          <h2>Moje porady:</h2>
          {parse(post.acfPrzepis.kotletPrzepisPorady)}
          </section>
         )}

        </div>

        {!!post.acfPrzepis.kotletPrzepisYoutubeId && (
          <section className="blog-post-yt">
            <YT url={post.acfPrzepis.kotletPrzepisYoutubeId} title={post.title}/>
          </section>
         )}

        {!!post.acfPrzepis.kotletPrzepisSkladniki && (
          <Adsense2 currentPath={location.pathname} />
        )}

        {!!post.acfPrzepis.kotletPrzepisKrok && (
          <PrzepisKroki kroki={post.acfPrzepis.kotletPrzepisKrok}/>
        )}

        {!!post.acfPrzepis.kotletPrzepisKrok && (
          <Adsense3 currentPath={location.pathname} />
        )}

        {!!post.acfPrzepis.kotletPrzepisTresc2 && (
        <div className="blog-post-body2">{parse(post.acfPrzepis.kotletPrzepisTresc2)}</div>
        )}

        <footer>
          <small className="blog-post-data">{post.date}</small>
          <Bio author={author}/>
        </footer>
      </article>

      <nav className="blog-post-nav">
        <ul>
          <li className="prev">
            {previous && (
              <Link to={previous.uri} rel="prev">
                ← {parse(previous.title)}
              </Link>
            )}
          </li>

          <li className="next">
            {next && (
              <Link to={next.uri} rel="next">
                {parse(next.title)} →
              </Link>
            )}
          </li>
        </ul>
      </nav>

      <Ads currentPath={location.pathname} />
      
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    # selecting the current post by id
    post: wpPost(id: { eq: $id }) {
      id
      excerpt
      content
      title
      date(formatString: "D MMMM YYYY [r.]", locale: "pl")
      dateSchema: date(formatString: "YYYY-MM-DD", locale: "pl")
      author {
        node {
          databaseId
          name
          description
        }
      }
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 85) {
                ...GatsbyImageSharpFluid_tracedSVG
                presentationHeight
                presentationWidth
              }
            }
          }
        }
      }
      terms {
        nodes {
          name
          uri
          id
        }
      }
      categories {
        nodes {
          name
        }
      }
      acfPrzepis {
        kotletPrzepisKrok {
          ilustracja {
            altText
            id
            localFile {
              publicURL
              childImageSharp {
                fluid(maxWidth: 500, quality: 85) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
          opis
        }
        kotletPrzepisCzas
        kotletPrzepisLiczbaPorcji
        kotletPrzepisPorady
        kotletPrzepisSkladniki
        kotletPrzepisTresc2
        kotletPrzepisYoutubeId
      }
    }

    # this gets us the previous post by id (if it exists)
    previous: wpPost(id: { eq: $previousPostId }) {
      uri
      title
    }

    # this gets us the next post by id (if it exists)
    next: wpPost(id: { eq: $nextPostId }) {
      uri
      title
    }

    site: site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
