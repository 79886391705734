import React from "react"
import getVideoId from 'get-video-id';

const YT = ({url, title}) => {

  const { id } = getVideoId(url);

  // const host = new URL(url);
  const embed = `https://www.youtube-nocookie.com/embed/${id}?rel=0`

  return (
    <iframe width="100%" height="320" src={embed} title={title} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
  );
};

export default YT