/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
// import { useStaticQuery, graphql } from "gatsby"

const Bio = ({ author }) => {

  return (
    <div>
      {/* {avatarUrl && (
        <img
          alt={author?.firstName || ``}
          className="bio-avatar"
          src={avatarUrl}
        />
      )} */}
      {author?.name && (
        <p className="bio"><strong>{author.name}.</strong>
        {` `}
        {author?.description || null} → <a href="https://www.instagram.com/paulina.domowa/">mój Instagram</a>.</p>
      )}
    </div>
  )
}

export default Bio
