import React from "react"
import { Link } from "gatsby"

const PostTerms = ({ terms }) => {

  if (!terms?.nodes || terms.nodes === 0) return null

  return (
    <nav className="post-categories">
      <ul>
        {terms.nodes.map((term) => (
          <li key={term.id}>
            <Link to={term.uri} rel="term tag">
                {term.name}
            </Link>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default PostTerms